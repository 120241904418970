/*
* @Author: Administrator
* @Date:   2018-08-04 10:51:09
* @Last Modified by:   Administrator
* @Last Modified time: 2018-10-09 14:30:12
*/
// eslint-disable-next-line prefer-const
let globalData = {
  firstRoute:"",
  url: "/api",
  domainUrl: "https://api.movexbot.com/api/v1",//正式
  picUrl: "https://api.movexbot.com/api/v1/file/pictures/",//正式
  lan: "en_US", //中文 zh_CN  英文 en_US
  mapUrl: "https://api.movexbot.com/api/v1/file/robot/", //地图
  fileUrl: "/Map/",
  exportFileUrl: "https://api.movexbot.com/api/v1/file/",
  yjhUrl: "h5.cleanrobotm.yijiahe.com",
  tcurl: "tc.movexbot.com"
 };
// const globalData = {
//   firstRoute:"",
//   url: "/api",
//   domainUrl: "http://192.168.0.31:8080/api/v1",//正式
//   picUrl: "http://192.168.0.31:8080/api/v1/file/pictures/",//正式
//   lan: "zh_CN", //中文 zh_CN  英文 en_US
//   mapUrl: "http://192.168.0.31:8080/api/v1/file/robot/", //地图
//   fileUrl: "/Map/",
//   exportFileUrl: "http://192.168.0.31:8080/api/v1/file/",
//   yjhUrl: "192.168.0.17:8888"
// // };
// const globalData = {
//   firstRoute:"",
//   url: "/api",
//   domainUrl: "https://test.movexbot.com:8087/api/v1",//正式
//   picUrl: "https://test.movexbot.com:8087/api/v1/file/pictures/",//正式
//   lan: "zh_CN", //中文 zh_CN  英文 en_US
//   mapUrl: "https://test.movexbot.com:8087/api/v1/file/robot/", //地图
//   fileUrl: "/Map/",
//   exportFileUrl: "https://test.movexbot.com:8087/api/v1/file/",   
//   yjhUrl: "192.168.0.34:8888",
//   tcurl: "192.168.0.50:8889"
// };
export {
    globalData
};      