import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
// import VueTabsSwipe from "vue-tabs-swiper"
// Vue.use(VueTabsSwipe);
import VueI18n from 'vue-i18n'
import zhCN from './lang/zh-CN';
import en from './lang/en';
import {
  Button, CellGroup, Field, Radio, RadioGroup, Tabbar, TabbarItem, Dialog, List, PullRefresh, Cell, Checkbox, CheckboxGroup,
  Popup, Collapse, CollapseItem, DatetimePicker, Uploader, SwipeCell, DropdownMenu, DropdownItem, Loading, Image as VanImage, ImagePreview , Tab, Tabs, Switch, Icon
} from 'vant';
import { globalData } from './utils/config';
import Nav from "@/components/Nav.vue";
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.component('Nav', Nav);
Vue.use(Button);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(VueI18n)
Vue.use(CellGroup);
Vue.use(Radio);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Dialog);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(DatetimePicker);
Vue.use(Uploader);
Vue.use(SwipeCell);
Vue.use(Loading);
Vue.use(VanImage);
Vue.use(ImagePreview);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Switch);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.config.productionTip = false

const messages = {
  en_US: {
    ...en,
  },
  zh_CN: {
    ...zhCN,
  }
} 
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en_US',//globalData.lan, // set locale 设置语言
  messages, // set locale messages
})
router.beforeResolve((to, from, next) => {
  console.log("beforeResolve:" + navigator.language + " to:" + to.name + " from:" + from.name + 'keepAlive TO:'+ to.meta.keepAlive + 'keepAlive FROM:'+ from.meta.keepAlive)
  next();
});
router.beforeEach((to, from, next) => {
  const preferredLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
  console.log('Preferred Language:', navigator.languages);
  console.log("navigator.language111:" + navigator.language + " to:" + to.name + " from:" + from.name)
  const lang = navigator.language.toLowerCase()
   if(lang.indexOf('en') !=-1){
      i18n.locale = "en_US";
      globalData.lan = "en_US";
   } else if(lang.indexOf('zh') !=-1 || lang.indexOf('cn') !=-1){
      i18n.locale = "zh_CN";
      globalData.lan = "zh_CN";
   } else {
      i18n.locale = "en_US";
      globalData.lan = "en_US";
   }
  // switch (navigator.language) {
  //   case "en":
  //   case "en-US":
  //     i18n.locale = "en_US";
  //     break;
  //   case "zh-CN":
  //   case "zh":
  //     i18n.locale = "zh_CN";
  //     break;
  //   default:
  //     i18n.locale = "en_US";
  //     break
  // }
  if (to.matched.some(record => record.meta.auth)) {
    //非登录页判断token和用户信息
    if (!localStorage.getItem('token') || !localStorage.getItem('userInfo')) {
      next({
        path: '/login'
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
new Vue({
  router,
 // store,
  i18n,
  render: h => h(App)
}).$mount('#app')
