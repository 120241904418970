import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      auth: true
    },
    redirect:'/robot',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/home/index.vue'),
    children: [
      {
        path: '/robot',
        name: 'Robot',
        meta: {
          auth: true,
          keepAlive: true,
          deepth: 1,
        },
        component: () => import(/* webpackChunkName: "robot" */ '@/pages/robot/index.vue')
      },
      {
        path: '/work',
        name: 'Work',
        meta: {
          auth: true,
          keepAlive: true,
          deepth: 1,
        },
        component: () => import(/* webpackChunkName: "work" */ '@/pages/work/index.vue')
      },
      {
        path: '/setting',
        name: 'Setting',
        meta: {
          auth: true,
          keepAlive: true,
        },
        component: () => import(/* webpackChunkName: "setting" */ '@/pages/setting/index.vue')
      },
      {
        path: '/fault',
        name: 'Fault',
        meta: {
          auth: true,
          keepAlive: true,
          deepth: 1,
        },
        component: () => import(/* webpackChunkName: "fault" */ '@/pages/fault/index.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/index.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '@/pages/register/index.vue')
  },
  {
    path: '/addRobot',
    name: 'AddRobot',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "addRobot" */ '@/pages/bindRobot/index.vue')
  },
  {
    path: '/robotDetail',
    name: 'RobotDetail',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "robotDetail" */ '@/pages/robotDetail/index.vue')
  },
  {
    path: '/workDetail',
    name: 'WorkDetail',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "workDetail" */ '@/pages/workDetail/index.vue')
  },
  {
    path: '/newFault',
    name: 'newFault',
    component: () => import(/* webpackChunkName: "newFault" */ '@/pages/newFault/index.vue')
  },
  {
    path: '/faultDetail',
    name: 'faultDetail',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "faultDetail" */ '@/pages/faultDetail/index.vue')
  },
  {
    path: '/newMaintain',
    name: 'newMaintain',
    component: () => import(/* webpackChunkName: "newFault" */ '@/pages/newMaintain/index.vue')
  },
  {
    path: '/maintainDetail',
    name: 'maintainDetail',
    component: () => import(/* webpackChunkName: "newFault" */ '@/pages/maintainDetail/index.vue')
  },
  {
    path: '/newRepair',
    name: 'newRepair',
    component: () => import(/* webpackChunkName: "newFault" */ '@/pages/newRepair/index.vue')
  },
  {
    path: '/repairDetail',
    name: 'repairDetail',
    component: () => import(/* webpackChunkName: "repairDetail" */ '@/pages/repairDetail/index.vue')
  },
  {
    path: '/aboutAs',
    name: 'AboutAs',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "aboutAs" */ '@/pages/aboutAs/index.vue')
  },
  {
    path: '/updatePassword',
    name: 'UpdatePassword',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "updatePassword" */ '@/pages/updatePassword/index.vue')
  },
  {
    path: '/email',
    name: 'email',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "updatePassword" */ '@/pages/email/index.vue')
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "forgetPassword" */ '@/pages/forgetPassword/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
